import React, { useCallback } from 'react';
// TODO: Add api calls to update serverside

/**
 * Managing the CRUD operations for claims
 *
 * @param {Claim[]} claims The list of claims
 * @param {React.Dispatch<React.SetStateAction<Claim[]>>} setClaims Action to set the state of the claims
 * @return {Claim} Returns a claim
 */
export const useClaimManagement = (
  claims: Claim[],
  setClaims: React.Dispatch<React.SetStateAction<Claim[]>>
): CRUDOperations<Claim> => ({
  create: useCallback(
    (newClaim: Claim) => {
      // Adds a new claim to the claims array
      setClaims([...claims, newClaim]);
    },
    [claims, setClaims]
  ),
  read: (claimId: string) => {
    return claims.find((claim) => claim.id == claimId);
  },
  update: useCallback(
    (claimId: string, newClaim: Claim) => {
      setClaims(
        claims.map((claim) => {
          if (claim.id === claimId) {
            return newClaim;
          }
          return claim;
        })
      );
    },
    [claims, setClaims]
  ),
  delete: useCallback(
    (claimId: string) => {
      setClaims(claims.filter((claim) => claim.id !== claimId));
      console.log(claims);
    },
    [claims, setClaims]
  ),
});
