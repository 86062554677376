import React from 'react';
import {
  SnackbarKey,
  useSnackbar,
  VariantType,
  WithSnackbarProps,
} from 'notistack';
import { IconButton, Tooltip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';

let useSnackbarRef: WithSnackbarProps;
export const SnackbarUtilsConfigurator: React.FC = () => {
  useSnackbarRef = useSnackbar();
  return null;
};

const close = (key: SnackbarKey | undefined) => (
  <IconButton
    onClick={() => {
      useSnackbarRef.closeSnackbar(key);
    }}
  >
    <CloseIcon />
  </IconButton>
);

const toast = (msg: string, variant: VariantType = 'default') => {
  useSnackbarRef.enqueueSnackbar(msg, { variant });
};

const showSuccess = (msg: string) => {
  toast(msg, 'success');
};

const showInfo = (msg: string) => {
  toast(msg, 'info');
};

const showWarning = (msg: string) => {
  toast(msg, 'warning');
};

const showError = (msg: string) => {
  useSnackbarRef.enqueueSnackbar(msg, {
    variant: 'error',
    persist: true,
    action: close,
  });
};

const getPermission = (key: SnackbarKey | undefined) => (
  <>
    <Tooltip title="Yes">
      <IconButton
        onClick={() => {
          useSnackbarRef.closeSnackbar(key);
          localStorage.setItem('trackingPermission', 'true');
          location.reload();
        }}
      >
        <CheckIcon />
      </IconButton>
    </Tooltip>
    <Tooltip title="No">
      <IconButton
        onClick={() => {
          useSnackbarRef.closeSnackbar(key);
          localStorage.setItem('trackingPermission', 'false');
        }}
      >
        <CloseIcon />
      </IconButton>
    </Tooltip>
  </>
);

const askForTrackingPermission = (msg: string) => {
  useSnackbarRef.enqueueSnackbar(msg, {
    variant: 'info',
    persist: true,
    action: getPermission,
  });
};

export {
  showSuccess,
  showInfo,
  showWarning,
  showError,
  askForTrackingPermission,
};
