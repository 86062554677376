import React from 'react';
import { Grid, Typography, Button } from '@mui/material';
import dayjs from 'dayjs';
import { styled } from '@mui/system';

const DateButton = styled(Button)(({ theme }) => ({
  minWidth: 62,
  padding: '0px',
  [theme.breakpoints.down(1367)]: {
    minWidth: 55,
  },
}));

const PeriodAmount = styled(Typography)({
  borderBottom: '1px solid lightgrey',
  padding: 6,
  fontSize: 22,
});

interface DateItemProps {
  period: string;
  periodAmount?: string;
  withPeriodAmount?: boolean;
  selectedDate?: DateRange;
  date?: DateRange;
  customDate?: boolean;
  onClick: () => void;
  openCustomDate?: boolean;
  disabled?: boolean;
}

const DateItem: React.FC<DateItemProps> = (props) => {
  const { period, periodAmount, selectedDate, date, onClick, disabled } = props;

  return (
    <DateButton
      variant="contained"
      color={
        dayjs(selectedDate?.from).format('YYYY-MM-DD') ===
          date?.from.format('YYYY-MM-DD') ||
        (selectedDate === undefined && date === undefined)
          ? 'secondary'
          : 'primary'
      }
      onClick={onClick}
      disabled={disabled}
    >
      <Grid
        container
        flexDirection="column"
        justifyContent="center"
        textAlign="center"
      >
        <PeriodAmount variant="h6">{periodAmount ?? ''}</PeriodAmount>
        <Typography variant="subtitle1" p={0} fontSize="0.75rem">
          {period}
        </Typography>
      </Grid>
    </DateButton>
  );
};

export default DateItem;
