import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  InputAdornment,
  TextField,
} from '@mui/material';
import { styled } from '@mui/system';
import React, { useState } from 'react';
import { useAuth0 } from '../../services/Auth';
import { YELLOW_COLOR } from '../CustomTheme/CustomTheme';

const AddTopicButton = styled(Button)(() => ({
  backgroundColor: YELLOW_COLOR,
  color: 'black',
  borderRadius: '44px',
  width: '28px',
  height: '28px',
  '&:hover': {
    backgroundColor: YELLOW_COLOR,
    opacity: 0.8,
    color: 'black',
  },
}));

interface AddTopicProps {
  onAddTopic: (newTopic: string) => void;
}

const AddTopic: React.FC<AddTopicProps> = ({ onAddTopic }) => {
  const { isAuthenticated } = useAuth0();
  const { loginWithRedirect } = useAuth0();

  const [newTopic, setNewTopic] = useState('');

  const [dialogOpen, setDialogOpen] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewTopic(event.target.value);
  };

  const handleAddTopic = () => {
    if (!newTopic.trim().length) return;
    onAddTopic(newTopic);
    setNewTopic('');
  };

  return (
    <>
      <TextField
        variant="outlined"
        value={newTopic}
        placeholder="Search for fact checks"
        autoFocus
        size="small"
        onChange={handleChange}
        onKeyDown={(event: React.KeyboardEvent) => {
          if (event.key == 'Enter' && isAuthenticated) handleAddTopic();
        }}
        fullWidth
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <AddTopicButton
                onClick={() => {
                  isAuthenticated ? handleAddTopic() : setDialogOpen(true);
                }}
              >
                Search
              </AddTopicButton>
            </InputAdornment>
          ),
        }}
      />

      <>
        <Dialog disableScrollLock open={dialogOpen}>
          <DialogTitle>
            Sign up/Log in to add custom topics. <br /> (It’s free, no payment
            details required)
          </DialogTitle>
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setDialogOpen(false)}
            >
              Close
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                loginWithRedirect({});
              }}
            >
              Login
            </Button>
          </DialogActions>
        </Dialog>
      </>
    </>
  );
};

export default AddTopic;
