/* eslint-disable new-cap */
import { Favicon } from '@factiverse/design-system';
import { Grid, Link } from '@mui/material';
import React from 'react';

/* The properties needed to display a link back to the original article of this source */
interface DisplayLinkProps {
  /* The link displayed in the UI */
  displayLink: string;
  /* The full URL */
  link: string;
}

/* A component to display the link to the article and its publishing date */
const DisplayLink: React.FC<DisplayLinkProps> = ({ displayLink, link }) => {
  return (
    <Link
      rel="noopener noreferrer"
      href={link}
      target="_blank"
      color="textSecondary"
    >
      <Grid
        container
        alignItems="center"
        spacing={1}
        justifyContent="center"
        className="DisplayLink"
      >
        <Grid item>
          <Favicon rootDomain={displayLink} />
        </Grid>
        <Grid item>{displayLink}</Grid>
      </Grid>
    </Link>
  );
};

export default DisplayLink;
