import { Grid, Typography, Link } from '@mui/material';
import React from 'react';

interface VerdictProps {
  source: string;
  verdict: string;
  url: string;
}

const Verdict: React.FC<VerdictProps> = ({ source, verdict, url }) => {
  return (
    <Link href={url} rel="noopener noreferrer" target="_blank">
      <Grid container spacing={1} alignItems="center">
        <Grid item>
          <Typography
            variant="subtitle1"
            color="gray"
            sx={{ textDecoration: 'underline' }}
          >
            Verdict by {source}:
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            variant="subtitle1"
            sx={{
              color: 'crimson',
              textTransform: 'capitalize',
            }}
          >
            {verdict}
          </Typography>
        </Grid>
      </Grid>
    </Link>
  );
};

export default Verdict;
