import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { exampleClaims } from '../../exampleClaims';
import { GlobalStateContext } from '../../services/GlobalState/global.context';
import { Button } from '@mui/material';

// ! Created for testing. Can be removed
const ClaimPage: React.FC = () => {
  const { claimId } = useParams();
  const [claim, setClaim] = useState<Claim>(exampleClaims[Number(claimId)]);
  const { claimManagement } = useContext(
    GlobalStateContext
  ) as GlobalStateContextType;

  useEffect(() => {
    const claimLookup = () => {
      const searchClaim = () => claimId && exampleClaims[Number(claimId)]; // TODO: Exchange with api request
      const tempClaim = searchClaim();
      if (tempClaim as Claim) {
        setClaim(tempClaim as Claim);
      }
    };
    claimLookup();
  }, [claim, claimId]);

  const updateClaim = () => {
    claim.claim = 'test';
    claimManagement.update(claim.id, claim);
  };

  return (
    <>
      {claim ? claim.claim : 'Not exist'}
      <Button onClick={updateClaim}>Update</Button>
    </>
  );
};

export default ClaimPage;
