import React, { useState, useEffect } from 'react';
import { DialogTitle, Dialog, useMediaQuery } from '@mui/material';
import { useAuth0 } from '../../services/Auth';
import { useTheme } from '@mui/material/styles';

const WelcomeDialog = () => {
  const { user, isAuthenticated, isNewLogin } = useAuth0();
  const [welcomeModalOpen, setWelcomeModalOpen] = useState<boolean>(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (isAuthenticated) {
      setWelcomeModalOpen(true);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (welcomeModalOpen) {
      setTimeout(() => setWelcomeModalOpen(false), 3000);
    }
  }, [welcomeModalOpen]);

  return (
    <Dialog
      onClose={() => setWelcomeModalOpen(false)}
      open={welcomeModalOpen}
      hideBackdrop
      disableScrollLock
      PaperProps={{
        style: isMobile
          ? {
              position: 'absolute',
              top: '8%',
            }
          : { position: 'absolute', top: '8%', right: '0%' },
      }}
    >
      <DialogTitle>
        Welcome{!isNewLogin && ` back`}
        {user && `, ${user?.given_name}`}!
      </DialogTitle>
    </Dialog>
  );
};

export default WelcomeDialog;
