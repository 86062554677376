import React from 'react';
import { AxiosInterceptorProvider } from './axios-intercept';
import { onRedirectCallback } from './Hooks/history';
import { Auth0Provider, useAuth0 } from './auth-context';
import authConfig from '../../authConfig.json';

export { useAuth0 };

/*
 * Auth0Provider for auth0 provided context inside of react children
 * Wraps AxiosInterceptorProvider inside.
 * cacheLocation : localStorage
 * returns: AuthProvider wrapper
 */
type AuthProviderProps = { children: React.ReactNode };
export const AuthProvider = ({ children }: AuthProviderProps) => {
  return (
    <Auth0Provider
      domain={authConfig.domain}
      client_id={authConfig.clientId}
      redirect_uri={window.location.origin}
      audience={authConfig.audience}
      cacheLocation="localstorage"
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens={true}
      scope={authConfig.scope}
    >
      <AxiosInterceptorProvider>{children}</AxiosInterceptorProvider>
    </Auth0Provider>
  );
};
