import { Box, Grid, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { useAuth0 } from '../../services/Auth';
import ClaimPage from '../ClaimPage/ClaimPage';
import Header from '../HeaderBar/Header';
import Home from '../Home/Home';
import NewUserWelcome from '../NewUserWelcome/NewUserWelcome';
import ProtectedRoute from '../ProtectedRoute/ProtectedRoute';
import SunLoading from '../SunLoading';
import WelcomeDialog from '../WelcomeDialog/WelcomeDialog';

const App: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { loading } = useAuth0();

  return (
    <Grid container direction={'column'}>
      <Grid item mb={2}>
        <Header />
      </Grid>
      <Grid container direction={'column'}>
        <Grid item alignItems={'center'}>
          <Box
            width={isMobile ? '90%' : '60%'}
            marginLeft="auto"
            marginRight="auto"
            paddingX={0}
          >
            {!loading ? (
              <Routes>
                <Route element={<ProtectedRoute />}>
                  <Route path="/claim/:claimId" element={<ClaimPage />} />
                </Route>
                <Route index element={<Home />} />
                <Route path="*" element={<Home />} />
              </Routes>
            ) : (
              <SunLoading loadingText="Loading newest data..." />
            )}
          </Box>
        </Grid>
      </Grid>
      <WelcomeDialog />
      <NewUserWelcome />
    </Grid>
  );
};

export default App;
