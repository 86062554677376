import * as Sentry from '@sentry/react';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './components/App/App';
import { SnackbarUtilsConfigurator } from './components/Snackbars';
import './css/main.css';
import './fonts/Arizona Serif/ArizonaSerif-Light.woff';
import './fonts/Arizona Serif/ArizonaSerif-Light.woff2';
import './fonts/DM mono/DMMono-Light.ttf';
import { AuthProvider } from './services/Auth';
import GlobalStateProvider from './services/GlobalState/global.context';
import { theme } from './utlis/theme';

Sentry.init({
  dsn: 'https://8221078763f0e8708011fbef385684ef@o4507537794859008.ingest.de.sentry.io/4507537796759632',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <Router>
        <GlobalStateProvider>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <SnackbarProvider
                anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
                maxSnack={1}
                autoHideDuration={3000}
              >
                <SnackbarUtilsConfigurator />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <App />
                </LocalizationProvider>
              </SnackbarProvider>
            </ThemeProvider>
          </StyledEngineProvider>
        </GlobalStateProvider>
      </Router>
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
