import React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import { Tooltip, Typography, Grid } from '@mui/material';
import { styled } from '@mui/system';
import SupportIcon from '../SupportIcon/SupportIcon';

interface FilterBarProps {
  filter: Array<Filter>;
  updateFilter: (newFilters: Array<Filter>) => void;
  supporting: number;
  balanced: number;
  disputing: number;
}

const CustomToggleButton = styled(ToggleButton)(() => ({
  padding: 0,
  paddingBottom: 4,
  borderTopLeftRadius: 0,
  borderTopRightRadius: 0,
  width: 120,
  height: 75,
  '& .MuiTypography-root': {
    fontFamily: 'DM Mono',
  },
}));

const FilterBar: React.FC<FilterBarProps> = ({
  filter,
  updateFilter,
  supporting,
  balanced,
  disputing,
}) => {
  const handleChange = (label: string) => {
    updateFilter(
      filter.map((item) => {
        if (item.label === label) return { ...item, selected: !item.selected };
        return item;
      })
    );
  };

  const total = supporting + balanced + disputing;

  return (
    <>
      {filter !== undefined && (
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <Grid item xs={12} md="auto">
            <Typography variant="h5">Found {total} sources:</Typography>
          </Grid>
          {supporting > 0 && (
            <Grid item xs={4} md="auto">
              <Tooltip title="Use Factiverse to find the most updated sources for this fact-check">
                <CustomToggleButton
                  value={'Supporting'}
                  selected={filter[0].selected}
                  onChange={() => handleChange('Supporting')}
                >
                  <SupportIcon
                    variant="supporting"
                    label="Supporting"
                    count={supporting}
                  />
                </CustomToggleButton>
              </Tooltip>
            </Grid>
          )}
          {balanced > 0 && (
            <Grid item xs={4} md="auto">
              <Tooltip title="Use Factiverse to find the most updated sources for this fact-check">
                <CustomToggleButton
                  value={'Neutral'}
                  selected={filter[2].selected}
                  onChange={() => handleChange('Neutral')}
                >
                  <SupportIcon
                    variant="neutral"
                    label="Neutral"
                    count={balanced}
                  />
                </CustomToggleButton>
              </Tooltip>
            </Grid>
          )}
          {disputing > 0 && (
            <Grid item xs={4} md="auto">
              <Tooltip title="Use Factiverse to find the most updated sources for this fact-check">
                <CustomToggleButton
                  value={'Disputing'}
                  selected={filter[1].selected}
                  onChange={() => handleChange('Disputing')}
                >
                  <SupportIcon
                    variant="disputing"
                    label="Disputing"
                    count={disputing}
                  />
                </CustomToggleButton>
              </Tooltip>
            </Grid>
          )}
        </Grid>
      )}
    </>
  );
};

export default FilterBar;
