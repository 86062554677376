import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth0 } from '../../services/Auth';

const ProtectedRoute = () => {
  const { user } = useAuth0();

  return !user ? <Navigate to="/home" replace /> : <Outlet />;
};
export default ProtectedRoute;
