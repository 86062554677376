import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { IconButton, Stack, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React from 'react';
import { useAuth0 } from '../../../services/Auth';
import { BIG_FONT } from '../../../utlis/theme';
import Avatar from './Avatar';

interface UserAvatarProps {
  handleClick: (event: React.MouseEvent<HTMLElement>) => void;
  openUserProfile: boolean;
}

const UserAvatar = (props: UserAvatarProps) => {
  const { handleClick, openUserProfile } = props;
  const { user } = useAuth0();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Stack direction="row" alignItems="center">
      {isMobile ? (
        <IconButton onClick={handleClick}>
          <Avatar />
        </IconButton>
      ) : (
        <>
          <Avatar />
          <Typography fontSize={BIG_FONT} p={0.5}>
            {user?.given_name ?? ''}
          </Typography>
          <IconButton onClick={handleClick}>
            {openUserProfile ? (
              <KeyboardArrowUpIcon sx={{ color: 'black' }} />
            ) : (
              <KeyboardArrowDownIcon sx={{ color: 'black' }} />
            )}
          </IconButton>
        </>
      )}
    </Stack>
  );
};

export default UserAvatar;
