import { Grid, Card } from '@mui/material';
import React from 'react';
import SourceItem from '../SourceItem/SourceItem';

/*
 * The properties needed to display a list of sources
 * in the UI and offer functionality.
 */
interface SourceListProps {
  claim: Claim;
  /** The list of sources relevant to a claim */
  sources?: Array<Source>;
  onFeedbackSourceRelevance: OnFeedbackSourceRelevance;
  onFeedbackSourceSupport: OnFeedbackSourceSupport;
  filter: Array<Filter>;
}

/*
 * Displays a list of sources
 */
export const SourceList: React.FC<SourceListProps> = ({
  claim,
  sources,
  onFeedbackSourceRelevance,
  onFeedbackSourceSupport,
  filter,
}) => {
  /**
   * Checks if the source should be hidden from results
   *
   * @param {Object} source The source
   * @return {boolean} True if the source should not be shown
   */
  function isSourceFiltered(source: Source): boolean {
    let filtered = false;
    filter.forEach((item) => {
      switch (item.label) {
        case 'Supporting':
          if (
            source.softmaxScore != undefined &&
            source.softmaxScore[1] >= 0.6 &&
            !item.selected
          )
            filtered = true;
          break;
        case 'Disputing':
          if (
            source.softmaxScore != undefined &&
            source.softmaxScore[1] < 0.4 &&
            !item.selected
          )
            filtered = true;
          break;
        case 'Neutral':
          if (
            source.softmaxScore != undefined &&
            source.softmaxScore[1] < 0.6 &&
            source.softmaxScore[1] >= 0.4 &&
            !item.selected
          )
            filtered = true;
          break;
        default:
          break;
      }
    });
    return filtered;
  }

  let supporting = 0;
  let balanced = 0;
  let disputing = 0;

  claim.evidence?.forEach((source) => {
    if (
      typeof source === 'undefined' ||
      typeof source.softmaxScore === 'undefined'
    )
      return;
    if (source.softmaxScore[1] < 0.4) disputing++;
    if (source.softmaxScore[1] >= 0.4 && source.softmaxScore[1] < 0.6)
      balanced++;
    if (source.softmaxScore[1] >= 0.6) supporting++;
  });

  const show =
    (filter[0].selected && supporting > 0) ||
    (filter[1].selected && disputing > 0) ||
    (filter[2].selected && balanced > 0);

  return (
    <>
      {show && (
        <Card variant="outlined" className="SourceListContainer">
          <Grid container spacing={4}>
            {sources?.map((source) => {
              return (
                !source.hide &&
                !isSourceFiltered(source) && (
                  <Grid key={source.id} item xs={12} md={6} xl={6}>
                    <SourceItem
                      claim={claim}
                      source={source}
                      onFeedbackSourceRelevance={onFeedbackSourceRelevance}
                      onFeedbackSourceSupport={onFeedbackSourceSupport}
                    />
                  </Grid>
                )
              );
            })}
          </Grid>
        </Card>
      )}
    </>
  );
};

export default SourceList;
