import { MenuOutlined } from '@mui/icons-material';
import {
  Button,
  Grid,
  Link,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';

import React, { useState } from 'react';
import logo from '../../img/logo.png';
import { useAuth0 } from '../../services/Auth';
import {
  BORDER_COLOR,
  BORDER_RADIUS,
  ISMOBILE_BREAKPOINT,
} from '../../utlis/theme';
import UserProfile from '../UserProfile/UserProfile';
import UserAvatar from './components/UserAvatar';

const Header = () => {
  const { user, isAuthenticated, loginWithRedirect } = useAuth0();
  const isMobile = useMediaQuery(ISMOBILE_BREAKPOINT);

  const [userProfileAnchorEl, setUserProfileAnchorEl] =
    useState<null | HTMLElement>(null);

  const handleClickUserProfile = (event: React.MouseEvent<HTMLElement>) => {
    setUserProfileAnchorEl(userProfileAnchorEl ? null : event.currentTarget);
  };

  const [menuAnchor, setMenuAnchor] = React.useState<null | HTMLElement>(null);
  const openMenu = Boolean(menuAnchor);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchor(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setMenuAnchor(null);
  };

  const handleCloseUserProfile = () => {
    setUserProfileAnchorEl(null);
  };

  const openUserProfile = Boolean(userProfileAnchorEl);
  const idUserProfile = openUserProfile ? 'simple-popper' : undefined;

  return (
    <Grid
      item
      container
      height="72px"
      alignItems="center"
      sx={{
        background: 'white',
        borderRadius: isMobile ? 0 : BORDER_RADIUS,
        boxShadow: '-4px 5px 28px -3px rgba(232, 234, 235,0.75)',
        border: `1px solid ${BORDER_COLOR}`,
        zIndex: 10,
        position: 'relative',
      }}
    >
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        justifyItems={isMobile ? 'center' : 'center'}
        item
        xs={isMobile ? 9 : 10}
        wrap={isMobile ? 'nowrap' : 'wrap'}
      >
        <Grid item ml={isMobile ? 2 : 6}>
          <Tooltip title="Visit factiverse.no">
            <Link
              href="https://factiverse.no"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={logo}
                alt="Factiverse Logo"
                width={'210'}
                height={'65'}
              />
            </Link>
          </Tooltip>
        </Grid>
        <Grid item mr={isMobile ? 2 : 6}>
          {isMobile ? (
            <>
              <Button
                id="basic-button"
                aria-controls={openMenu ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={openMenu ? 'true' : undefined}
                onClick={handleClick}
                sx={{ color: 'black', pr: 3 }}
              >
                <MenuOutlined />
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={menuAnchor}
                open={openMenu}
                onClose={handleCloseMenu}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <MenuItem onClick={handleCloseMenu}>
                  <Typography variant="h6">
                    <Link
                      href="https://factiverse.ai/api-offerings"
                      target="blank"
                      sx={{
                        textDecoration: 'none',
                        color: 'black',
                      }}
                    >
                      API
                    </Link>
                  </Typography>
                </MenuItem>
              </Menu>
            </>
          ) : (
            <Stack spacing={4} direction="row">
              <Typography>
                <Link
                  href="https://factiverse.ai/api-offerings"
                  target="blank"
                  sx={{
                    textDecoration: 'none',
                    color: 'black',
                  }}
                >
                  API
                </Link>
              </Typography>
            </Stack>
          )}
        </Grid>
      </Grid>
      <Grid
        container
        item
        xs
        justifyContent="center"
        sx={{ borderLeft: `1px solid ${BORDER_COLOR}` }}
      >
        {isAuthenticated ? (
          <UserAvatar
            handleClick={handleClickUserProfile}
            openUserProfile={openUserProfile}
          />
        ) : (
          <Button
            variant={'contained'}
            aria-label={'Check claim'}
            onClick={() => loginWithRedirect({})}
            color={'secondary'}
          >
            <Typography variant="button">Login</Typography>
          </Button>
        )}
      </Grid>
      {user && (
        <UserProfile
          open={openUserProfile}
          anchorEl={userProfileAnchorEl}
          handleClose={handleCloseUserProfile}
          id={idUserProfile as string}
        />
      )}
    </Grid>
  );
};

export default Header;
