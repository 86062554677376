import { Grid, Typography, Box } from '@mui/material';
import React from 'react';

interface SupportIconProps {
  variant: string; // supporting or disputing
  count: number;
  label: string;
}

const SupportIcon: React.FC<SupportIconProps> = ({ variant, label, count }) => {
  const getSxStyle = () => {
    console.log(variant);
    switch (variant) {
      case 'supporting':
        return '#86c252';

      case 'disputing':
        return '#fe8661';

      case 'neutral':
        return '#ffcd0f';

      default:
        return 'grey';
    }
  };

  return (
    <Grid container direction="column" alignItems="center" spacing={1}>
      <Grid item>
        <Box sx={{ position: 'relative', width: 118, height: 40 }}>
          <Box
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              bgcolor: getSxStyle(),
            }}
          >
            <Typography
              variant="h4"
              sx={{ color: 'black', fontFamily: 'DM Mono' }}
            >
              {count}
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item>
        <Typography sx={{ color: 'black' }}>{label}</Typography>
      </Grid>
    </Grid>
  );
};

export default SupportIcon;
