import React, { useState, useEffect } from 'react';
import {
  DialogTitle,
  Dialog,
  useMediaQuery,
  Typography,
  Grid,
} from '@mui/material';
import { useAuth0 } from '../../services/Auth';
import { useTheme } from '@mui/material/styles';
import { BellSimple } from 'phosphor-react';

interface NotificationDialogProps {
  firstClick: boolean;
}

const NotificationDialog = (props: NotificationDialogProps) => {
  const { firstClick } = props;
  const { isNewLogin } = useAuth0();
  const [notificationDialogOpen, setNotificationDialogOpen] =
    useState<boolean>(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (firstClick && isNewLogin) {
      setTimeout(() => setNotificationDialogOpen(true), 7000);
    }
  }, [firstClick, isNewLogin]);

  useEffect(() => {
    if (notificationDialogOpen) {
      setTimeout(() => setNotificationDialogOpen(false), 5000);
    }
  }, [notificationDialogOpen]);

  return (
    <Dialog
      onClose={() => setNotificationDialogOpen(false)}
      open={notificationDialogOpen}
      hideBackdrop
      disableScrollLock
      PaperProps={{
        style: isMobile
          ? {
              position: 'absolute',
              top: '8%',
            }
          : { position: 'absolute', top: '8%', right: '0%' },
      }}
    >
      <DialogTitle>
        <Grid container alignItems="center">
          <BellSimple size={isMobile ? 30 : 48} />
          <Typography
            variant="h6"
            sx={{ width: isMobile ? 200 : 300, p: 1, pl: 3 }}
          >
            Click the boxes to filter sources
          </Typography>
        </Grid>
      </DialogTitle>
    </Dialog>
  );
};

export default NotificationDialog;
