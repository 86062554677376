import { createTheme } from '@mui/material/styles';

export const YELLOW_COLOR = '#f8c608';

// work in progress, used to manage colors, fonts, etc.
declare module '@mui/material/styles/createTheme' {
  interface ThemeOptions {
    themeName?: string;
  }
}

const palette = {
  primary: {
    main: '#ffffff',
  },
  secondary: {
    main: YELLOW_COLOR,
  },
  backgroundTrue: {
    main: '#0ccb58',
  },
  backgroundFalse: {
    main: '#f72525',
  },
  text: {
    primary: '#000000',
    secondary: '#333333',
  },
  background: {
    default: '#ffffff',
  },
};

const typography = {
  fontSize: 12,
};

export default createTheme({
  palette,
  typography,
});
