import { SelectWithSearch } from '@factiverse/design-system';
import { Grid, Typography } from '@mui/material';
import React from 'react';
import { languageLabels } from '../../utils';
import DatePicker from './components/DatePicker';

interface SettingsPanelProps {
  dates: DateRange | undefined;
  setDates: (value: DateRange | undefined) => void;
  language: string;
  languages: Array<string>;
  setLanguageByLabel: (newLanguage: string) => void;
  loading: boolean;
}

const SettingsPanel: React.FC<SettingsPanelProps> = ({
  dates,
  setDates,
  language,
  languages,
  setLanguageByLabel,
  loading,
}) => {
  return (
    <Grid container spacing={4} pb={3}>
      <Grid item>
        <Typography sx={{ fontStyle: 'italic' }} fontSize="1rem" pb={1}>
          Date Range
        </Typography>
        <DatePicker setDates={setDates} dates={dates} />
      </Grid>
      <Grid item>
        <Typography sx={{ fontStyle: 'italic' }} fontSize="1rem" pb={1}>
          Language
        </Typography>
        <SelectWithSearch
          options={languages.map((item) => {
            return languageLabels[item] ?? item;
          })}
          option={languageLabels[language]}
          setOption={setLanguageByLabel}
          fixedOptions={['English', 'Norwegian']}
          loading={loading}
          small={true}
        />
      </Grid>
    </Grid>
  );
};

export default SettingsPanel;
