/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useEffect, createContext, useState } from 'react';
import createAuth0Client, {
  Auth0Client,
  Auth0ClientOptions,
  getIdTokenClaimsOptions,
  GetTokenSilentlyOptions,
  GetTokenWithPopupOptions,
  IdToken,
  LogoutOptions,
  PopupConfigOptions,
  PopupLoginOptions,
  RedirectLoginOptions,
  User,
} from '@auth0/auth0-spa-js';
import { useAxiosInterceptor } from './axios-intercept';
import jwtDecode from 'jwt-decode';

type Auth0DecodedToken = {
  user_new: boolean;
};

type Auth0ContextOptions = {
  isAuthenticated: boolean;
  isTokenSet: boolean;
  user: User | undefined;
  isNewLogin: boolean;
  loading: boolean;
  popupOpen: boolean;
  loginWithPopup: (
    options?: PopupLoginOptions,
    config?: PopupConfigOptions
  ) => Promise<void>;
  handleRedirectCallback: (path?: string) => Promise<void>;
  getIdTokenClaims: (
    options?: getIdTokenClaimsOptions
  ) => Promise<IdToken | undefined>;
  loginWithRedirect: (options?: RedirectLoginOptions) => Promise<void>;
  getTokenSilently: (options?: GetTokenSilentlyOptions) => Promise<any>;
  getTokenWithPopup: (
    options?: GetTokenWithPopupOptions,
    config?: PopupConfigOptions
  ) => Promise<string | undefined>;
  logout: (options?: LogoutOptions) => void;
  mockReturnValue?: any;
};

/*
 * Auth0ProviderOptions
 * children : passed
 * onRedirectCallback : redirect from browserHistory
 */
export type Auth0ProviderOptions = Auth0ClientOptions & {
  children: React.ReactElement;
  onRedirectCallback: Auth0ContextOptions['handleRedirectCallback'];
};

/*
 * Creates Auth0 Context
 */
export const Auth0Context = createContext({} as Auth0ContextOptions);
export const useAuth0 = () => {
  const context = useContext<Auth0ContextOptions>(Auth0Context);
  if (context === undefined) {
    throw new Error('useAuth must be used within a CommonAuthProvider');
  }
  return context;
};

/*
 * Auth0Provider Component
 */
export const Auth0Provider: React.FC<Auth0ProviderOptions> = ({
  children,
  onRedirectCallback,
  ...initOptions
}) => {
  const { isTokenSet } = useAxiosInterceptor();
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [user, setUser] = useState<User | undefined>(undefined);
  const [auth0Client, setAuth0] = useState<Auth0Client>();
  const [loading, setLoading] = useState<boolean>(true);
  const [popupOpen, setPopupOpen] = useState<boolean>(false);
  const [isNewLogin, setIsNewLogin] = useState<boolean>(false);

  useEffect(() => {
    const initAuth0 = async () => {
      const auth0FromHook = await createAuth0Client(initOptions);
      setAuth0(auth0FromHook);

      if (
        window.location.search.includes('code=') &&
        window.location.search.includes('state=')
      ) {
        const { appState } = await auth0FromHook.handleRedirectCallback();
        await onRedirectCallback(appState?.targetUrl);
      }
      const isAuthenticated = await auth0FromHook.isAuthenticated();

      setIsAuthenticated(isAuthenticated);

      if (isAuthenticated) {
        const accessToken = await auth0FromHook.getTokenSilently();
        const decode: Auth0DecodedToken = await jwtDecode(accessToken);
        const isNewLogin = await decode.user_new;
        setIsNewLogin(isNewLogin);
      }
      if (isAuthenticated) {
        const user = await auth0FromHook.getUser();
        setUser(user);
      }

      setLoading(false);
    };
    initAuth0();
    // eslint-disable-next-line
  }, []);

  const loginWithPopup: Auth0ContextOptions['loginWithPopup'] = async (
    options,
    config
  ) => {
    setPopupOpen(true);
    try {
      await auth0Client?.loginWithPopup(options, config);
    } catch (error) {
      console.error(error);
    } finally {
      setPopupOpen(false);
    }
    const user = await auth0Client?.getUser();
    setUser(user);
    setIsAuthenticated(true);
  };

  const handleRedirectCallback: Auth0ContextOptions['handleRedirectCallback'] =
    async (url) => {
      setLoading(true);
      await auth0Client?.handleRedirectCallback(url);
      const user = await auth0Client?.getUser();
      setLoading(false);
      setIsAuthenticated(true);
      setUser(user);
    };
  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        loading,
        isTokenSet,
        isNewLogin,
        popupOpen,
        loginWithPopup,
        handleRedirectCallback,
        getIdTokenClaims: async (options) =>
          auth0Client?.getIdTokenClaims(options),
        loginWithRedirect: async (options) =>
          auth0Client?.loginWithRedirect(options),
        getTokenSilently: async (options) =>
          auth0Client?.getTokenSilently(options),
        getTokenWithPopup: async (options, config) =>
          auth0Client?.getTokenWithPopup(options, config),
        logout: (options) => auth0Client?.logout(options),
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};
