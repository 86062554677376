/* eslint-disable new-cap */
import {
  Box,
  Card,
  Grid,
  Typography,
  useMediaQuery,
  Tooltip,
  CardActionArea,
} from '@mui/material';
import React, { useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import dayjs from 'dayjs';
import { useTheme } from '@mui/material/styles';
import Verdict from '../Verdict/Verdict';
import { Favicon } from '@factiverse/design-system';
import { useAuth0 } from '../../services/Auth';

interface ManualFactCheckProps {
  manualFactCheck: SearchResult;
  onSearchClaim: OnSearchClaim;
  onDeleteClaim: OnDeleteClaim;
  claims: Claim[];
  clickCount: () => void;
}

const ManualFactCheck: React.FC<ManualFactCheckProps> = ({
  manualFactCheck,
  onSearchClaim,
  onDeleteClaim,
  claims,
  clickCount,
}) => {
  const { isAuthenticated } = useAuth0();

  const selectedClaim = useMemo(
    () => claims.find((a) => a.claim.includes(manualFactCheck.claim)),
    [claims, manualFactCheck.claim]
  );

  const isSelectedClaim = useMemo(
    () => selectedClaim?.claim === manualFactCheck.claim,
    [manualFactCheck.claim, selectedClaim?.claim]
  );

  const handleClick = () => {
    clickCount();
    isSelectedClaim
      ? onDeleteClaim(selectedClaim)
      : onSearchClaim({ claim: manualFactCheck.claim, id: uuidv4() });
  };

  const clampText = (text: string, maxChars: number) => {
    if (text.length >= maxChars) return text.slice(0, maxChars) + '...';
    return text;
  };
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Card>
      <Box paddingX={isMobile ? 1 : 2} py={1}>
        <Grid container spacing={1} columns={32} alignItems="center">
          <Grid item xs={3} lg={2}>
            <Tooltip
              title={
                <Typography sx={{ textTransform: 'capitalize' }}>
                  {manualFactCheck.domainName}
                </Typography>
              }
            >
              <Grid item>
                <Favicon rootDomain={manualFactCheck.domain} />
              </Grid>
            </Tooltip>
          </Grid>
          <Grid item xs lg container direction="column" spacing={2}>
            <Grid item>
              {isAuthenticated ? (
                <CardActionArea onClick={handleClick}>
                  <Tooltip
                    title={
                      <Typography>Click to find additional sources</Typography>
                    }
                    arrow
                  >
                    <Typography variant={isMobile ? 'body1' : 'h5'}>
                      {manualFactCheck.claim}
                    </Typography>
                  </Tooltip>
                </CardActionArea>
              ) : (
                <Typography variant={isMobile ? 'body1' : 'h5'}>
                  {manualFactCheck.claim}
                </Typography>
              )}
            </Grid>
            <Grid item container justifyContent="space-between" spacing={1}>
              {manualFactCheck.label && (
                <Grid item>
                  <Verdict
                    source={manualFactCheck.domain}
                    verdict={
                      manualFactCheck.label4level &&
                      manualFactCheck.label4level != 'Other'
                        ? manualFactCheck.label4level
                        : clampText(manualFactCheck.label, isMobile ? 16 : 42)
                    }
                    url={manualFactCheck.url}
                  />
                </Grid>
              )}
              <Grid item>
                <Typography variant="subtitle1" color="gray">
                  {dayjs(manualFactCheck.publishDate)
                    .format('DD.MM.YYYY')
                    .toString()}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
};

export default ManualFactCheck;
