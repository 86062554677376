import React, { useState } from 'react';
import {
  Grid,
  DialogTitle,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
} from '@mui/material';
import dayjs from 'dayjs';
import DateRangePicker from './DateRangePicker';
import DateItem from './DateItem';

const oneDayAgoDate = {
  from: dayjs().subtract(1, 'day'),
  to: dayjs(),
};

const threeDaysAgoDate = {
  from: dayjs().subtract(3, 'day'),
  to: dayjs(),
};

const oneWeekAgoDate = {
  from: dayjs().subtract(1, 'week'),
  to: dayjs(),
};

const twoWeeksAgoDate = {
  from: dayjs().subtract(2, 'week'),
  to: dayjs(),
};

interface DatePickerProps {
  setDates: (value: DateRange | undefined) => void;
  dates: DateRange | undefined;
  disabled?: boolean;
}

const DatePicker = (props: DatePickerProps) => {
  const { setDates, dates, disabled } = props;

  const [openCustomDate, setOpenCustomDate] = useState<boolean>(false);
  const [customDate, setCustomDate] = useState<DateRange>(twoWeeksAgoDate);

  const datePickerValues = [
    { period: 'day', periodAmount: 1, date: oneDayAgoDate },
    { period: 'days', periodAmount: 3, date: threeDaysAgoDate },
    { period: 'week', periodAmount: 1, date: oneWeekAgoDate },
  ];

  const handleCloseCustomDateDialog = () => {
    setOpenCustomDate(false);
  };

  return (
    <>
      <Grid container spacing={2}>
        {datePickerValues.map((value, key) => (
          <Grid item key={key}>
            <DateItem
              key={key}
              period={value.period}
              periodAmount={value.periodAmount.toString()}
              selectedDate={dates}
              date={value.date}
              onClick={() => {
                setDates(value.date);
              }}
              disabled={disabled}
            />
          </Grid>
        ))}
        <Grid item>
          <DateItem
            period="Custom"
            periodAmount={'x'}
            date={customDate}
            selectedDate={dates}
            onClick={() => {
              setOpenCustomDate(true);
            }}
            disabled={disabled}
          />
        </Grid>
        <Grid item>
          <DateItem
            period="Any"
            periodAmount={'∞'}
            date={undefined}
            selectedDate={dates}
            onClick={() => {
              setDates(undefined);
            }}
            disabled={disabled}
          />
        </Grid>
      </Grid>
      <Dialog
        disableScrollLock
        onClose={() => setOpenCustomDate(true)}
        open={openCustomDate}
      >
        <DialogTitle>Custom date range</DialogTitle>
        <DialogContent>
          <DateRangePicker dates={customDate} setDates={setCustomDate} />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={handleCloseCustomDateDialog}
          >
            Close
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              setOpenCustomDate(false);
              setDates(customDate);
            }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DatePicker;
