import React, { useState, useEffect } from 'react';
import { Box, Modal, Typography, Button } from '@mui/material';
import { styled } from '@mui/system';
import { YELLOW_COLOR } from '../CustomTheme/CustomTheme';
import { Cpu } from 'phosphor-react';
import { useAuth0 } from '../../services/Auth';

const WelcomeMsgBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  background: 'white',
  borderRadius: 4,
  padding: theme.spacing(2),
  textAlign: 'center',
  [theme.breakpoints.down('sm')]: {
    width: 300,
  },
}));

const CpuIcon = styled(Cpu)(() => ({
  color: YELLOW_COLOR,
  paddingTop: 10,
}));

const List = styled('ul')(({ theme }) => ({
  position: 'relative',
  left: '10%',
  [theme.breakpoints.down('sm')]: {
    left: '0%',
  },
}));

const ListItem = styled('li')({
  textAlign: 'left',
});

const NewUserWelcome = () => {
  const { user, isNewLogin } = useAuth0();
  const [openWelcomeMsg, setWelcomeMsg] = useState<boolean>(false);

  useEffect(() => {
    if (user && isNewLogin) {
      setWelcomeMsg(true);
    }
  }, [user, isNewLogin]);

  return (
    <Modal
      open={openWelcomeMsg}
      onClose={() => setWelcomeMsg(false)}
      disableEscapeKeyDown
      closeAfterTransition
      disableAutoFocus
    >
      <WelcomeMsgBox>
        <Typography variant="h6">
          Welcome to the world&apos;s largest fact-checking database
        </Typography>
        <CpuIcon size={100} />
        <List>
          <ListItem>
            <Typography variant="subtitle1">
              Filter fact-checks in 40+ languages
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="subtitle1">Add your own topics</Typography>
          </ListItem>
          <ListItem>
            <Typography variant="subtitle1">Stay ahead of fake-news</Typography>
          </ListItem>
        </List>
        <Button
          onClick={() => setWelcomeMsg(false)}
          variant="contained"
          color="secondary"
          sx={{ mt: 1 }}
        >
          Let&apos;s go!
        </Button>
      </WelcomeMsgBox>
    </Modal>
  );
};

export default NewUserWelcome;
