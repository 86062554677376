import React from 'react';
import { Button, Typography, Popover, Box, Grid } from '@mui/material';
import { useAuth0 } from '../../services/Auth';

interface UserProfileProps {
  open: boolean;
  anchorEl: HTMLElement | null;
  handleClose: () => void;
  id: string;
}

const UserProfile = (props: UserProfileProps) => {
  const { open, anchorEl, handleClose, id } = props;
  const { user, isAuthenticated, logout } = useAuth0();
  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      disableScrollLock
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      {user && (
        <Box p={2}>
          <Grid container flexDirection="column">
            <Typography fontSize="1rem" p={0.5}>
              {user.given_name} {user.family_name}
            </Typography>
            <Typography fontSize="0.875rem" p={0.5} pb={2}>
              {user.email}
            </Typography>
            {isAuthenticated && (
              <Button
                variant={'contained'}
                aria-label={'Check claim'}
                onClick={() => logout({ returnTo: window.location.origin })}
                color={'secondary'}
              >
                <Typography variant="button">Logout</Typography>
              </Button>
            )}
          </Grid>
        </Box>
      )}
    </Popover>
  );
};

export default UserProfile;
