/* eslint-disable new-cap */
import { Box, Card, Grid, Pagination, Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useAuth0 } from '../../services/Auth';
import ClaimItem from '../ClaimItem/ClaimItem';
import ManualFactCheck from '../ManualFactCheck/ManualFactCheck';

const RESULT_LIMIT = 10;

/*
 * The properties needed to provide functionality for all buttons
 */
interface ManualFactChecksProps {
  manualFactChecks: Array<SearchResult>;
  claims: Array<Claim>;
  onSearchClaim: OnSearchClaim;
  onDeleteClaim: OnDeleteClaim;
  onFeedbackCredibility: OnFeedbackCredibility;
  onFeedbackSourceRelevance: OnFeedbackSourceRelevance;
  onFeedbackSourceSupport: OnFeedbackSourceSupport;
  onCheckClaim: OnCheckClaim;
  clickCount: () => void;
  splitExactMatches: boolean;
  currentTopic: string;
}

const ManualFactChecks: React.FC<ManualFactChecksProps> = ({
  manualFactChecks,
  claims,
  onSearchClaim,
  onDeleteClaim,
  onFeedbackCredibility,
  onFeedbackSourceRelevance,
  onFeedbackSourceSupport,
  onCheckClaim,
  clickCount,
  splitExactMatches,
  currentTopic,
}) => {
  const { isAuthenticated } = useAuth0();
  const [page, setPage] = useState(1);
  const [resultsPerPage, setResultsPerPage] =
    useState<SearchResult[]>(manualFactChecks);
  const [exactMatches, relatedMatches] = useMemo(() => {
    return [
      resultsPerPage.filter((item) => item.exactMatch),
      resultsPerPage.filter((item) => !item.exactMatch),
    ];
  }, [resultsPerPage]);

  useEffect(() => {
    const firstSliceNumber = page * RESULT_LIMIT - 10;
    const secondSliceNumber = page * RESULT_LIMIT;

    if (page === 1) {
      setResultsPerPage(manualFactChecks.slice(0, secondSliceNumber));
    } else {
      setResultsPerPage(
        manualFactChecks.slice(firstSliceNumber, secondSliceNumber)
      );
    }
  }, [manualFactChecks, page]);

  return (
    <Grid container direction="column" spacing={2}>
      {!isAuthenticated && (
        <Grid item>
          <Typography>
            For more recent fact checks, topics and other features please sign
            up or log in
          </Typography>
        </Grid>
      )}
      {manualFactChecks.length > 0 ? (
        <Grid item>
          <Grid
            container
            direction="column"
            spacing={1}
            style={{ display: 'table' }}
          >
            {splitExactMatches &&
              (exactMatches.length > 0 ? (
                <Grid item>
                  <Typography variant="subtitle1">
                    Here are the most relevant fact-checks for{' '}
                    <i>{currentTopic.toLocaleUpperCase()}</i>
                  </Typography>
                </Grid>
              ) : (
                <Card>
                  <Box p={4}>
                    <Typography textAlign="center" variant="subtitle1">
                      Sorry, we found no exact matches for this topic
                    </Typography>
                  </Box>
                </Card>
              ))}
            {exactMatches.map((manualFactCheck) => {
              return (
                <Grid key={uuidv4()} item>
                  <ManualFactCheck
                    manualFactCheck={manualFactCheck}
                    onSearchClaim={onSearchClaim}
                    onDeleteClaim={onDeleteClaim}
                    claims={claims}
                    clickCount={clickCount}
                  />
                  {claims.map((claim) => {
                    return (
                      manualFactCheck.claim === claim.claim && (
                        <Box mt={1} key={uuidv4()}>
                          <ClaimItem
                            claim={claim}
                            onDeleteClaim={onDeleteClaim}
                            onFeedbackCredibility={onFeedbackCredibility}
                            onFeedbackSourceRelevance={
                              onFeedbackSourceRelevance
                            }
                            onFeedbackSourceSupport={onFeedbackSourceSupport}
                            onCheckClaim={onCheckClaim}
                          />
                        </Box>
                      )
                    );
                  })}
                </Grid>
              );
            })}
            {splitExactMatches && relatedMatches.length > 0 && (
              <Grid item>
                <Typography variant="subtitle1" pt={4}>
                  Here are some fact-checks related to{' '}
                  <i>{currentTopic.toLocaleUpperCase()}</i>
                </Typography>
              </Grid>
            )}
            {relatedMatches.map((manualFactCheck) => {
              return (
                <Grid key={uuidv4()} item>
                  <ManualFactCheck
                    manualFactCheck={manualFactCheck}
                    onSearchClaim={onSearchClaim}
                    onDeleteClaim={onDeleteClaim}
                    claims={claims}
                    clickCount={clickCount}
                  />
                  {claims.map((claim) => {
                    return (
                      manualFactCheck.claim === claim.claim && (
                        <Box mt={1} key={uuidv4()}>
                          <ClaimItem
                            claim={claim}
                            onDeleteClaim={onDeleteClaim}
                            onFeedbackCredibility={onFeedbackCredibility}
                            onFeedbackSourceRelevance={
                              onFeedbackSourceRelevance
                            }
                            onFeedbackSourceSupport={onFeedbackSourceSupport}
                            onCheckClaim={onCheckClaim}
                          />
                        </Box>
                      )
                    );
                  })}
                </Grid>
              );
            })}
          </Grid>
          {manualFactChecks.length > RESULT_LIMIT && (
            <Pagination
              color="secondary"
              count={Math.ceil(manualFactChecks.length / 10)}
              page={page}
              onChange={(_e, value) => {
                setPage(value);
              }}
              sx={{ justifyContent: 'center', display: 'flex', pt: 2 }}
            />
          )}
        </Grid>
      ) : (
        <Card>
          <Box p={4}>
            <Typography textAlign="center" variant="subtitle1">
              Sorry, we found no fact checks, try changing the date range or
              rephrase the topic.
            </Typography>
          </Box>
        </Card>
      )}
    </Grid>
  );
};

export default ManualFactChecks;
