import { createTheme } from '@mui/material/styles';

export const SMALL_FONT = '0.75rem';
export const MEDIUM_FONT = '0.875rem';
export const BIG_FONT = '0.938rem';
export const LARGE_FONT = '1.125rem';
export const TITLE_FONT = '3rem';

export const YELLOW = '#f8c408';
export const LIGHT_GREY = '#F5F7F8';
export const RED = '#ff1010';
export const DARK_GREY = '#6c727a';
export const BG_COLOR = '#fafbfc';
export const BORDER_COLOR = '#e8eaeb';

export const SUPPORTED = '#d7fbcd';
export const MIXED = '#fbe593';
export const DISPUTED = '#fee7df';
export const FOUNDCLAIM = '#E7E7E7';

export const ENTITY = '#A0ABFF';

export const BORDER_RADIUS = '36px';

export const ISMOBILE_BREAKPOINT = '(max-width:1024px)';

const palette = {
  primary: {
    main: '#ffffff',
  },
  secondary: {
    main: YELLOW,
  },
  backgroundTrue: {
    main: '#0ccb58',
  },
  backgroundFalse: {
    main: '#f72525',
  },
  text: {
    primary: '#000000',
    secondary: '#333333',
  },
  background: {
    default: '#ffffff',
  },
};

export const theme = createTheme({
  palette,
  typography: {
    fontFamily: 'DM Sans',
  },
});
