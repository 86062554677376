export const TRANSLATIONS_EN = {
  helloWorld: 'Hello World',
  claim: 'CLAIM',
  showSources: 'view sources',
  hideSources: 'hide',
  totalArticleScore: 'Total Article Score',
  source: 'SOURCE',
  date_format_one: '{{-date, YYYY/MM/DD}}',
  date_format_two: '{{date, DD-MM-YYYY}}',
  keyWithCount: 'Anne has bought {{count}} book',
  keyWithCount_plural: 'Anne has bought {{count}} books',
  // TODO: #69 Arrange translations into namespaces
  global: {
    ok: 'OK',
  },
};
