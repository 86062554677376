import React, { useMemo } from 'react';
import { Stack, Paper, useMediaQuery, Typography } from '@mui/material';
import { useTheme, styled } from '@mui/material/styles';
import { SourceStatistic } from '../../services/API/api.service';
import { languageLabels } from '../../utils';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  width: 'inherit',
  height: '140px',
  position: 'relative',
  [theme.breakpoints.down('lg')]: {
    width: 'auto',
    zIndex: -1,
  },
}));

const ItemAlignment = styled(Typography)({
  top: '45%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  position: 'relative',
});

const BottomText = styled(Typography)(({ theme }) => ({
  position: 'absolute',
  bottom: '0%',
  left: '50%',
  transform: 'translate(-50%, 0%)',
  padding: theme.spacing(2, 0),
}));

interface SourceStatisticsProps {
  sourceStatisticsData: SourceStatistic[];
}

interface SourceStatisticsItemProps {
  value: string;
  count: number;
}

const SourceStatisticsItem = ({ value, count }: SourceStatisticsItemProps) => {
  return (
    <Stack direction="row" alignItems="center" justifyContent="center">
      <Typography variant="h5" style={{ textTransform: 'capitalize' }}>
        {value}
      </Typography>
      <Typography variant="h5" ml={1}>
        ({count})
      </Typography>
    </Stack>
  );
};

interface ItemBottomTextProps {
  text: string;
}

const ItemBottomText = ({ text }: ItemBottomTextProps) => {
  return <BottomText variant="subtitle1">{text}</BottomText>;
};

const SourceStatistics = (props: SourceStatisticsProps) => {
  const { sourceStatisticsData } = props;
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('lg'));

  const totalFactChecks = useMemo(() => {
    return sourceStatisticsData.reduce((a, b) => a + b.number, 0);
  }, [sourceStatisticsData]);

  const [topLanguages, topSources] = useMemo(() => {
    const languages = sourceStatisticsData.map((a) => a.lang);
    const sources = sourceStatisticsData.map((a) => a.source);
    const uniqueData = (data: string[]) => [...new Set(data)];

    const languagesWithValues = uniqueData(languages).map((uniqueLanguage) => ({
      language: uniqueLanguage,
      factChecksByLanguage: sourceStatisticsData
        .filter((a) => a.lang === uniqueLanguage)
        .reduce((a, b) => a + b.number, 0),
    }));

    const sourcesWithValues = uniqueData(sources).map((uniqueSource) => ({
      source: uniqueSource,
      factChecksBySource: sourceStatisticsData
        .filter((a) => a.source === uniqueSource)
        .reduce((a, b) => a + b.number, 0),
    }));

    return [
      languagesWithValues
        .sort((a, b) => b.factChecksByLanguage - a.factChecksByLanguage)
        .slice(0, 3),
      sourcesWithValues
        .sort((a, b) => b.factChecksBySource - a.factChecksBySource)
        .slice(0, 3),
    ];
  }, [sourceStatisticsData]);

  return (
    <Stack direction={isTablet ? 'column' : 'row'} spacing={2} width="100%">
      <Item>
        <ItemAlignment variant="h4">{totalFactChecks}</ItemAlignment>
        <ItemBottomText text="Total fact-checks" />
      </Item>
      <Item>
        <div style={{ position: 'relative' }}>
          {topLanguages.map((lang, key) => (
            <div key={key}>
              <SourceStatisticsItem
                value={languageLabels[lang.language] ?? 'Other'}
                count={lang.factChecksByLanguage}
              />
            </div>
          ))}
        </div>
        <ItemBottomText text="Top Languages" />
      </Item>
      <Item>
        <div style={{ position: 'relative' }}>
          {topSources.map((source, key) => (
            <div key={key}>
              <SourceStatisticsItem
                value={source.source}
                count={source.factChecksBySource}
              />
            </div>
          ))}
        </div>
        <ItemBottomText text="Most Articles" />
      </Item>
    </Stack>
  );
};

export default SourceStatistics;
