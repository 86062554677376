import React, { useState } from 'react';
import { useClaimManagement } from './claim.context';
import { exampleClaims } from '../../exampleClaims';

export const GlobalStateContext =
  React.createContext<GlobalStateContextType | null>(null);

const GlobalStateProvider: React.FC<React.ReactNode> = ({ children }) => {
  const [claims, setClaims] = useState<Claim[]>(exampleClaims);
  const claimManagement = useClaimManagement(claims, setClaims);
  return (
    <GlobalStateContext.Provider value={{ claimManagement, claims }}>
      {children}
    </GlobalStateContext.Provider>
  );
};

export default GlobalStateProvider;
