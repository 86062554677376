import React from 'react';
import { Grid, Typography } from '@mui/material';

interface SunLoadingProps {
  loadingText: string;
}

const SunLoading = (props: SunLoadingProps) => {
  const { loadingText } = props;
  return (
    <Grid item>
      <div className="container">
        <div className="sun"></div>
        <div className="dot-flashing"></div>
        <Typography
          variant="subtitle1"
          sx={{ color: 'grey', paddingTop: '1em' }}
        >
          {loadingText}
        </Typography>
      </div>
    </Grid>
  );
};

export default SunLoading;
