import React, { useEffect, useState, useContext } from 'react';
import { AxiosRequestConfig } from 'axios';
import { useAuth0 } from './auth-context';
import authConfig from '../../authConfig.json';
import { factiverseApi } from '../API/api.service';

const AxiosInterceptorContext = React.createContext<{
  isTokenSet: boolean;
}>({
  isTokenSet: false,
});

/*
 * Axios Token interceptor which sets the access_token in factiverseApi axios client
 */
const setAxiosTokenInterceptor = async (accessToken: string): Promise<void> => {
  factiverseApi.interceptors.request.use(async (config: AxiosRequestConfig) => {
    if (accessToken) {
      if (config.headers) {
        config.headers['Authorization'] = `Bearer ${accessToken}`;
      } else {
        config.headers = {
          Authorization: `Bearer ${accessToken}`,
        };
      }
    }
    return config;
  });
};

/*
 * useAxiosInterceptor : Axios Context
 */
export const useAxiosInterceptor = () => {
  const context = useContext(AxiosInterceptorContext);
  if (context === undefined) {
    throw new Error(
      'useAxiosInterceptor must be used within an AxiosInterceptorProvider'
    );
  }
  return context;
};

type AxiosInterceptorProviderProps = { children: React.ReactNode };

/*
 * AxiosInterceptorProvider
 * children : AxiosInterceptorProviderProps
 * returns: AxiosInterceptor Context and isTokenSet for access_token in header
 */
export const AxiosInterceptorProvider = ({
  children,
}: AxiosInterceptorProviderProps) => {
  const { getTokenSilently } = useAuth0();
  const [isTokenSet, setIsTokenSet] = useState<boolean>(false);

  useEffect(() => {
    const getAccessToken = async () => {
      const audience = authConfig.audience as string;
      const accessToken = await getTokenSilently({ audience });
      await setAxiosTokenInterceptor(accessToken);
      setIsTokenSet(true);
    };
    getAccessToken();
  }, [getTokenSilently]);

  return (
    <AxiosInterceptorContext.Provider value={{ isTokenSet }}>
      {children}
    </AxiosInterceptorContext.Provider>
  );
};
