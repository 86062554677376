import React, { useEffect, useMemo } from 'react';
import { useMediaQuery, Typography, Collapse } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CountUp from 'react-countup';

interface HeadlineProps {
  display: boolean;
  totalFactChecks: number | undefined;
}

const Headline = (props: HeadlineProps) => {
  const { display, totalFactChecks } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Update this value occasionally
  const DEFAULT_TOTAL = '209167';
  // Memoize the old count so it doesn't get overwritten before CountUp can animate
  const storedTotal = useMemo(
    () => parseInt(localStorage.getItem('totalFactChecks') ?? DEFAULT_TOTAL),
    []
  );

  useEffect(() => {
    if (totalFactChecks != undefined)
      localStorage.setItem('totalFactChecks', totalFactChecks?.toString());
  }, [totalFactChecks]);

  return (
    <Collapse in={display} timeout={800}>
      <Typography
        align="center"
        fontSize={isMobile ? '1.5rem' : '2.2rem'}
        py={1}
      >
        Access the world&#39;s largest fact-checking database
      </Typography>
      <Typography align="center" fontSize={isMobile ? '2.5rem' : '2.7rem'}>
        {totalFactChecks == undefined ? (
          // No animation while waiting for new value
          <CountUp start={storedTotal} end={storedTotal} />
        ) : (
          // Count up to new value
          <CountUp start={storedTotal} end={totalFactChecks} />
        )}
      </Typography>
      <Typography pb={3} align="center" variant="h6">
        <i>Fact-checks and counting</i>
      </Typography>
    </Collapse>
  );
};

export default Headline;
