import axios, { AxiosResponse } from 'axios';

const baseURL = 'https://prod.factiverse.ai/v1/';

export const factiverseApi = axios.create({
  timeout: 500000,
  headers: {
    accept: 'application/json',
    'Content-Type': 'application/json',
  },
  baseURL: baseURL,
});

interface StanceDetectionResponse {
  claim: string;
  evidence: Array<Evidence>;
  finalPrediction: number;
  finalScore: number;
}

interface Evidence {
  authors: Array<string>;
  doc: string;
  domain: string;
  domainName: string;
  evidenceSnippet: string;
  keywords: Array<string>;
  labelDescription: string;
  predictedLabel: 0 | 1;
  publishDate: string;
  searchEngine: string;
  snippet: string;
  softmaxScore: [number, number];
  title: string;
  url: string;
}

/**
 * Send a stance detection request to the api
 *
 * @param {string} claim The claim to be checked
 * @param {string} language The language of the claim
 * @param {boolean} logging True if the server should log the request, false is it is only for testing
 * @return {Object} The api response
 */
export function stanceDetectionRequest(
  claim: string,
  language: string,
  logging: boolean
): Promise<AxiosResponse<StanceDetectionResponse>> {
  console.log('Stance detection request to: ' + baseURL);
  return factiverseApi.post('stance_detection', {
    claim: claim,
    lang: language,
    logging: logging,
  });
}

interface ClaimDetectionResponse {
  detectedClaims: Array<Claim>;
}

interface Claim {
  claim: string;
  score: number;
}

/**
 * Send a claim detection request to the api
 *
 * @param {string} text The text to be checked for claims
 * @param {string} language The language of the text
 * @param {boolean} logging True if the server should log the request, false is it is only for testing
 * @return {Object} The api response
 */
export function claimDetectionRequest(
  text: string,
  language: string,
  logging: boolean
): Promise<AxiosResponse<ClaimDetectionResponse>> {
  return factiverseApi.post('claim_detection', {
    lang: language,
    text: text,
    logging: logging,
  });
}

interface FeedbackResponse {
  status: boolean;
}

/**
 * Send feedback on claim credibility to the api
 *
 * @param {string} claim The claim to give feedback on
 * @param {boolean} userAgrees True if the user confirms the credibility assessment
 * @param {language} language The language of the claim
 * @param {boolean} logging True if the server should log the request, false is it is only for testing
 * @return {Object} The api response
 */
export function feedbackCredibilityRequest(
  claim: string,
  userAgrees: boolean,
  language: string,
  logging: boolean
): Promise<AxiosResponse<FeedbackResponse>> {
  return factiverseApi.post('feedback', {
    feedbackType: 'claimStance',
    payload: {
      claim: claim,
      userAgrees: userAgrees,
      lang: language,
      logging: logging,
    },
  });
}

/**
 * Send feedback on source relevance to the api
 *
 * @param {string} claim The claim to which the source belongs to
 * @param {boolean} userIsRelevant True if the user confirms the relevance of the source
 * @param {language} language The language of the claim
 * @param {Object} evidence The source
 * @param {boolean} logging True if the server should log the request, false is it is only for testing
 * @return {Object} The api response
 */
export function feedbackSourceRelevanceRequest(
  claim: string,
  userIsRelevant: boolean,
  language: string,
  evidence: Source,
  logging: boolean
): Promise<AxiosResponse<FeedbackResponse>> {
  return factiverseApi.post('feedback', {
    feedbackType: 'sourceRelevance',
    payload: {
      claim: claim,
      userIsRelevant: userIsRelevant,
      lang: language,
      evidence: evidence,
      logging: logging,
    },
  });
}

/**
 * Send feedback on source support to the api
 *
 * @param {string} claim The claim to which the source belongs to
 * @param {boolean} userAgrees True if the user confirms the support of the source
 * @param {language} language The language of the claim
 * @param {Object} evidence The source
 * @param {boolean} logging True if the server should log the request, false is it is only for testing
 * @return {Object} The api response
 */
export function feedbackSourceSupportRequest(
  claim: string,
  userAgrees: boolean,
  language: string,
  evidence: Source,
  logging: boolean
): Promise<AxiosResponse<FeedbackResponse>> {
  return factiverseApi.post('feedback', {
    feedbackType: 'sourceStance',
    payload: {
      claim: claim,
      userAgrees: userAgrees,
      lang: language,
      evidence: evidence,
      logging: logging,
    },
  });
}

interface ClaimsSearchPostRequest {
  reverseSortPubDate: boolean;
  lang: string;
  logging: boolean;
  query: string;
  searchEngine: Array<string>;
  fromDate?: string;
  toDate?: string;
  size?: number;
}
interface ClaimSearchPostResponse {
  significantTerms: JSONArray;
  searchResults: Array<SearchResult>;
}

/**
 * Requests manual fact checks and word cloud for a given topic
 *

 * @param {boolean} reverseSortPubDate Determines the sorting of results
 * @param {string} language The language of the query
 * @param {boolean} logging True if the server should log this request
 * @param {string} query The search term
 * @param {Object} searchEngine The search engine to find results with
 * @param {string} fromDate The start of the date range of fact checks
 * @param {string} toDate The end of the date range of fact checks
 * @param {number} size How many results are requested
 * @return {Object} A wordcloud and related manual fact checks
 */
export function claimSearchPostRequest(
  reverseSortPubDate: boolean,
  language: string,
  logging: boolean,
  query: string,
  searchEngine: Array<string>,
  fromDate?: string,
  toDate?: string,
  size?: number
): Promise<AxiosResponse<ClaimSearchPostResponse>> {
  let request: ClaimsSearchPostRequest = {
    reverseSortPubDate: reverseSortPubDate,
    lang: language,
    logging: logging,
    query: query,
    searchEngine: searchEngine,
  };
  if (size !== undefined) request = { ...request, size: size };
  if (fromDate !== undefined && toDate !== undefined)
    request = { ...request, fromDate: fromDate, toDate: toDate };
  return factiverseApi.post('claim_search', request);
}

/**
 * For a given time frame a fact checking site will publish a number of articles.
 *
 * @property {string} lang The language of the source
 * @property {number} lang The number of articles published by the source
 * @property {string} lang The name of the source
 *
 * @example {'lang': 'en', 'number': 5, 'source': 'politifact'}
 */
export interface SourceStatistic {
  lang: string;
  number: number;
  source: string;
}

/**
 * The response to a request to GET /claim_search
 *
 * @returns Stats on available languages and number of published articles by source
 */
interface ClaimSearchGetResponse {
  sourceStatistics: Array<SourceStatistic>;
  supportedLanguages: Array<SupportedLanguage>;
  totalFactChecks?: string;
}

/**
 * Requests manual fact checks and word cloud for a given topic
 *
 * @param {string} fromDate The start of the date range of fact checks in yyyy-MM-dd format
 * @param {string} toDate The end of the date range of fact checks in yyyy-MM-dd format
 * @return {Object} Stats on available languages and number of published articles by source
 */
export function claimSearchGetRequest(
  fromDate?: string,
  toDate?: string
): Promise<AxiosResponse<ClaimSearchGetResponse>> {
  let parameters = '';
  if (fromDate !== undefined && toDate !== undefined)
    parameters = '?fromDate=' + fromDate + '&toDate=' + toDate;
  return factiverseApi.get('statistics/claim_search' + parameters);
}
