/* eslint-disable new-cap */
/* eslint-disable  @typescript-eslint/no-unused-vars */
import { Box, Button, Grid, Typography } from '@mui/material';
import axios from 'axios';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import {
  SourceStatistic,
  claimSearchGetRequest,
  claimSearchPostRequest,
  feedbackCredibilityRequest,
  feedbackSourceRelevanceRequest,
  feedbackSourceSupportRequest,
  stanceDetectionRequest,
} from '../../services/API/api.service';
import { useAuth0 } from '../../services/Auth';
import '../../translations/i18n';
import { getLanguageCode } from '../../utils';
import AddTopic from '../AddTopic/AddTopic';
import Footer from '../Footer';
import Headline from '../Headline/Headline';
import ManualFactChecks from '../ManualFactChecks/ManualFactChecks';
import NotificationDialog from '../NotificationDialog/NotificationDialog';
import SettingsPanel from '../SettingsPanel/SettingsPanel';
import { askForTrackingPermission } from '../Snackbars';
import SourceStatistics from '../SourceStatistics/SourceStatistics';
import SunLoading from '../SunLoading';
require('languagedetect');

const RESULT_SIZE = 100;

enum ClaimsActionKind {
  Add = 'ADD',
  Delete = 'DELETE',
  Update = 'Update',
}

type ClaimsAction =
  | { type: ClaimsActionKind.Add; payload: { claim: Claim } }
  | { type: ClaimsActionKind.Delete; payload: { id: string } }
  | {
      type: ClaimsActionKind.Update;
      payload: {
        id: string;
        language?: string;
        hide?: boolean;
        evidence?: Source[];
        score?: number;
        showSources?: boolean;
        isBeingChecked?: boolean;
        hasBeenChecked?: boolean;
        userAgrees?: boolean;
      };
    };

export const App: React.FC = () => {
  const { isAuthenticated } = useAuth0();

  /**
   * A URL parameter to set the language of stance-detection and claim-search
   * Use it by adding /?lang=no or /?lang=en in the URL
   * This overwrites the detected language of claims and should only be used for testing purposes
   */
  const [searchParams, setSearchParams] = useSearchParams();

  // list of supported languages in ISO 639-1 codes, e.g. "en" representing "English" as returned by the API
  const [languages, setLanguages] = useState<Array<string>>(['en']);

  // currently selected language
  const [language, setLanguage] = useState<string>(
    searchParams.get('lang') ?? 'en'
  );
  const setLanguageByLabel = (newLanguage: string) => {
    setLanguage(getLanguageCode(newLanguage));
  };

  const [totalFactChecks, setTotalFactChecks] = useState<number | undefined>(
    undefined
  );

  const [findSourcesClickCount, setFindSourcesClickCount] = useState<number>(0);

  // show/hide the number fo total fact checks
  const [showHeadline, setShowHeadline] = useState(true);

  const [dates, setDates] = useState<DateRange | undefined>({
    from: dayjs().subtract(1, 'week'),
    to: dayjs(),
  });

  const [manualFactChecks, setManualFactChecks] = useState(
    new Array<SearchResult>()
  );
  const [sourceStatistics, setSourceStatistics] = useState<
    Array<SourceStatistic>
  >([]);

  const [loading, setLoading] = useState(false);
  const [loadingStatistics, setLoadingStatistics] = useState(false);
  const [topic, setTopic] = useState('all topics');
  const [topics, setTopics] = useState<string[]>([]);

  useEffect(() => {
    if (isAuthenticated) {
      axios
        .get('https://mock.factiverse.ai/v1/factisearch')
        .then(function (data) {
          setTopics(data.data);
        })
        .catch(function (error: string) {
          console.error(error);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setTopics(['All Topics']);
    }
  }, [isAuthenticated]);

  const toDate = dates?.to.format('YYYY-MM-DD').toString();
  const fromDate = dates?.from.format('YYYY-MM-DD').toString();

  const onAddTopic = (newTopic: string) => {
    const duplicate = topics.some((element) => {
      if (
        element.localeCompare(newTopic, undefined, { sensitivity: 'accent' }) ==
        0
      )
        return true;
    });
    if (duplicate) return;
    if (topics.indexOf(newTopic) == -1) {
      setTopics([...topics, newTopic]);
    }
    setTopic(newTopic);
  };

  // ! Set this to true in production
  // true if the server should log the requests, set to false during testing to avoid unnecessary logging
  const logging = true;

  const containsClaim = (claims: Claim[], claim: Claim): boolean => {
    let found = false;
    claims.forEach((currentClaim) => {
      if (
        currentClaim.claim.replace(/\s/g, '') == claim.claim.replace(/\s/g, '')
      )
        found = true;
    });
    return found;
  };

  const claimsReducer = (state: Claim[], action: ClaimsAction): Claim[] => {
    const updatedClaims = [...state];
    switch (action.type) {
      case ClaimsActionKind.Add:
        if (containsClaim(state, action.payload.claim)) return state;
        updatedClaims.unshift(action.payload.claim);
        return updatedClaims;
      case ClaimsActionKind.Delete:
        return state.filter((claim) => {
          if (claim.id == action.payload.id) return false;
          return true;
        });
      case ClaimsActionKind.Update:
        return state.map((claim) => {
          if (claim.id == action.payload.id) {
            return {
              ...claim,
              language: action.payload.language ?? claim.language,
              hide: action.payload.hide ?? claim.hide,
              evidence: action.payload.evidence ?? claim.evidence,
              score: action.payload.score ?? claim.score,
              isBeingChecked:
                action.payload.isBeingChecked ?? claim.isBeingChecked,
              hasBeenChecked:
                action.payload.hasBeenChecked ?? claim.hasBeenChecked,
              userAgrees: action.payload.userAgrees ?? claim.userAgrees,
            };
          } else {
            return claim;
          }
        });
      default:
        return state;
    }
    return state;
  };

  // Contains the current list of claims and their sources
  const [claims, claimsDispatch] = useReducer(claimsReducer, []);

  /**
   * Sends a textual claim to the API.
   *
   * @param {string} toCheck The textual claim to check
   */
  const checkClaim = useCallback(
    (toCheck: Claim) => {
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      const LanguageDetect = require('languagedetect');
      const languageDetector = new LanguageDetect();
      const languageClaim = languageDetector.detect(toCheck.claim)[0][0];
      let lang = 'en';
      if (languageClaim == 'norwegian') {
        lang = 'no';
      }
      stanceDetectionRequest(toCheck.claim, lang, logging)
        .then((response) => {
          const evidence = response.data.evidence.map((result) => {
            return {
              ...result,
              id: uuidv4(),
              hide: false,
            };
          });
          claimsDispatch({
            type: ClaimsActionKind.Update,
            payload: {
              id: toCheck.id,
              language: lang,
              evidence: evidence,
              score: response.data.finalScore,
              isBeingChecked: false,
              hasBeenChecked: true,
              showSources: true,
            },
          });
        })
        .catch((error) => {
          console.error(
            'Error in checkClaim: ' +
              error.response +
              ' toCheck: ' +
              toCheck.claim
          );
          // TODO: #54 Implement better error handling for API calls
          claimsDispatch({
            type: ClaimsActionKind.Delete,
            payload: {
              id: toCheck.id,
            },
          });
          alert('An error occured: ' + error + ' Please try again.');
        });
    },
    [logging]
  );

  const addClaim = useCallback(
    (newClaim: Claim) => {
      newClaim.id = uuidv4();
      claimsDispatch({
        type: ClaimsActionKind.Add,
        payload: { claim: newClaim },
      });
      setSearchParams({ claim: newClaim.claim });
    },
    [setSearchParams]
  );

  const onSearchClaim = useCallback(
    (newClaim: Claim) => {
      addClaim(newClaim);
      claimsDispatch({
        type: ClaimsActionKind.Update,
        payload: { id: newClaim.id, isBeingChecked: true },
      });
      checkClaim(newClaim);
    },
    [addClaim, checkClaim]
  );

  /**
   * Gets triggered when the user gives feedback on claim support (thumbs up/down)
   *
   * @param {object} claim The claim to give feedback on
   * @param {boolean} userAgrees True if thumbs up
   */
  const onFeedbackCredibility: OnFeedbackCredibility = (
    claim: Claim,
    userAgrees: boolean
  ) => {
    feedbackCredibilityRequest(
      claim.claim,
      userAgrees,
      claim?.language ?? 'en',
      logging
    )
      .then(() => {
        claimsDispatch({
          type: ClaimsActionKind.Update,
          payload: { id: claim.id, userAgrees: userAgrees },
        });
      })
      .catch((error) => {
        console.error(
          'Error in onFeedbackCredibility: ' +
            error.response +
            ' claim: ' +
            claim
        );
        alert('An error occured: ' + error + ' Please try again.');
      });
  };

  /**
   * Gets triggered when the user gives feedback on the relevance of a source (thumbs up/down)
   *
   * @param {object} claim The claim to give feedback on
   * @param {object} source The source to give feedback on
   * @param {boolean} userIsRelevant True if thumbs up (=relevant)
   */
  const onFeedbackSourceRelevance: OnFeedbackSourceRelevance = (
    claim: Claim,
    source: Source,
    userIsRelevant: boolean
  ) => {
    feedbackSourceRelevanceRequest(
      claim.claim,
      userIsRelevant,
      claim?.language ?? 'en',
      source,
      logging
    )
      .then(() => {
        const newSource = { ...source, userAgreesRelevance: userIsRelevant };
        const newEvidence = claim.evidence?.map((currentSource) => {
          if (currentSource.id == source.id) return newSource;
          return currentSource;
        });
        claimsDispatch({
          type: ClaimsActionKind.Update,
          payload: { id: claim.id, evidence: newEvidence },
        });
      })
      .catch((error) => {
        console.error(
          'Error in onFeedbackSourceRelevance: ' +
            error.response +
            ' claim: ' +
            claim
        );
        alert('An error occured: ' + error + ' Please try again.');
      });
  };

  /**
   * Gets triggered when the user gives feedback on the support of a source (thumbs up/down)
   *
   * @param {object} claim The claim to give feedback on
   * @param {object} source The source to give feedback on
   * @param {boolean} userAgrees True if thumbs up
   */
  const onFeedbackSourceSupport: OnFeedbackSourceSupport = (
    claim: Claim,
    source: Source,
    userAgrees: boolean
  ) => {
    feedbackSourceSupportRequest(
      claim.claim,
      userAgrees,
      claim?.language ?? 'en',
      source,
      logging
    )
      .then(() => {
        const newSource = { ...source, userAgreesSupport: userAgrees };
        const newEvidence = claim.evidence?.map((currentSource) => {
          if (currentSource.id == source.id) return newSource;
          return currentSource;
        });
        claimsDispatch({
          type: ClaimsActionKind.Update,
          payload: { id: claim.id, evidence: newEvidence },
        });
      })
      .catch((error) => {
        console.error(
          'Error in onFeedbackSourceSupport: ' +
            error.response +
            ' claim: ' +
            claim
        );
        alert('An error occured: ' + error + ' Please try again.');
      });
  };

  /**
   * Gets triggered when the user clicks 'delete claim'
   * Removes the selected claim from the results and text.
   *
   * @param {Object} selectedClaim The claim to remove
   */
  const onDeleteClaim: OnDeleteClaim = (selectedClaim: Claim) => {
    setSearchParams();
    claimsDispatch({
      type: ClaimsActionKind.Delete,
      payload: { id: selectedClaim.id },
    });
  };

  // executed anytime claims or showHighlighting is updated
  useEffect(() => {
    const claimsToCheck: Array<Claim> = [];
    claims.forEach((claim) => {
      if (!claim.hasBeenChecked && !claim.isBeingChecked) {
        claimsToCheck.push(claim);
      }
    });
    if (claimsToCheck.length <= 0) {
      return;
    } else {
      claimsToCheck.forEach((claim) => {
        claimsDispatch({
          type: ClaimsActionKind.Update,
          payload: { id: claim.id, isBeingChecked: true },
        });
        checkClaim(claim);
      });
    }
  }, [checkClaim, claims]);

  // hide the headline on user interaction
  useEffect(() => {
    if (topic != 'all topics') setShowHeadline(false);
  }, [topic, dates]);

  useEffect(() => {
    const claim = searchParams.get('claim');
    if (claim != undefined) {
      addClaim({ id: uuidv4(), claim: claim });
    }
    if (localStorage.getItem('trackingPermission') == null)
      askForTrackingPermission('Allow us to collect anonymous usage data?');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Sends a single claim that hasn't been checked yet to the Factiverse server
   *
   * @param {string} claimId The claim to check
   */
  const onCheckClaim = useCallback(
    (claimId: string) => {
      claims.forEach((claim) => {
        if (
          claim.id == claimId &&
          !claim.hasBeenChecked &&
          !claim.isBeingChecked
        ) {
          const claimToCheck = claim;
          claimsDispatch({
            type: ClaimsActionKind.Update,
            payload: { id: claimId, isBeingChecked: true },
          });
          checkClaim(claimToCheck);
          return;
        }
      });
    },
    [checkClaim, claims]
  );

  useEffect(() => {
    setLoadingStatistics(true);
    (isAuthenticated
      ? claimSearchGetRequest(fromDate, toDate)
      : axios.get('https://mock.factiverse.ai/v1/statistics/claim_search')
    )
      .then((response) => {
        setLanguages(
          response.data.supportedLanguages.map((item: { code: unknown }) => {
            return item.code;
          })
        );
        setSourceStatistics(response.data.sourceStatistics);
        const total = response.data.totalFactChecks;
        total && setTotalFactChecks(parseInt(total));
      })
      .catch((err) => {
        console.error('Error while getting claim searching', err);
        setSourceStatistics([]);
        setLanguages([]);
      })
      .finally(() => setLoadingStatistics(false));
  }, [fromDate, isAuthenticated, toDate]);

  useEffect(() => {
    setLoading(true);
    const searchTerm = topic == 'all topics' ? '' : topic;
    (isAuthenticated
      ? claimSearchPostRequest(
          true,
          language,
          logging,
          searchTerm,
          ['fact_search_elasticsearch'],
          fromDate,
          toDate,
          RESULT_SIZE
        )
      : axios.get('https://mock.factiverse.ai/v1/claim_search')
    )
      .then((response) => {
        const searchResults = response.data.searchResults;
        searchResults.sort((a: SearchResult, b: SearchResult) => {
          const aDate = a.publishDate;
          const bDate = b.publishDate;
          return Date.parse(aDate) < Date.parse(bDate) ? 1 : -1;
        });
        setManualFactChecks(searchResults);
      })
      .catch((err) => {
        console.error('error while claim search', err);
        setManualFactChecks([]);
      })
      .finally(() => setLoading(false));
  }, [topic, language, logging, fromDate, toDate, isAuthenticated]);

  return (
    <React.Fragment>
      <Grid container direction={'column'} alignItems="stretch">
        <Grid item>
          <Headline display={showHeadline} totalFactChecks={totalFactChecks} />

          {/* source statistics data */}
          {loadingStatistics ? (
            <SunLoading loadingText="Loading newest data..." />
          ) : (
            <Box mt={1}>
              {sourceStatistics.length > 0 && (
                <SourceStatistics sourceStatisticsData={sourceStatistics} />
              )}
            </Box>
          )}
        </Grid>
        <Grid item mt={4}>
          <Typography variant="h5">
            Stay on top of the latest fact-checks
          </Typography>
        </Grid>
        <Grid item xs>
          <Grid container spacing={2} py={2}>
            <Grid item xs>
              <Grid container spacing={2} alignItems="center">
                {topics.map((item) => {
                  return (
                    <Grid key={item} item>
                      <Button
                        variant="contained"
                        color={item == topic ? 'secondary' : 'primary'}
                        onClick={() => {
                          setTopic(item);
                        }}
                      >
                        <Typography variant="button">{item}</Typography>
                      </Button>
                    </Grid>
                  );
                })}
                <Grid item xs={12} pb={2}>
                  <AddTopic onAddTopic={onAddTopic} />
                </Grid>
              </Grid>
            </Grid>
            {isAuthenticated && (
              <Grid item xs={12}>
                <SettingsPanel
                  dates={dates}
                  setDates={setDates}
                  language={language}
                  languages={languages}
                  setLanguageByLabel={setLanguageByLabel}
                  loading={loadingStatistics}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item mb={7}>
          {loading ? (
            <SunLoading loadingText="Loading fact checks..." />
          ) : (
            <Grid item>
              <ManualFactChecks
                claims={claims}
                manualFactChecks={manualFactChecks}
                onSearchClaim={onSearchClaim}
                onDeleteClaim={onDeleteClaim}
                onFeedbackCredibility={onFeedbackCredibility}
                onFeedbackSourceSupport={onFeedbackSourceSupport}
                onFeedbackSourceRelevance={onFeedbackSourceRelevance}
                onCheckClaim={onCheckClaim}
                clickCount={() =>
                  setFindSourcesClickCount(findSourcesClickCount + 1)
                }
                splitExactMatches={topic != 'all topics'}
                currentTopic={topic}
              />
            </Grid>
          )}
        </Grid>
        <Footer />
      </Grid>
      <NotificationDialog firstClick={findSourcesClickCount === 1} />
    </React.Fragment>
  );
};

export default App;
