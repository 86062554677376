import * as React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import TextField from '@mui/material/TextField';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { Grid, Tooltip, Typography } from '@mui/material';

interface DateRangePickerProps {
  dates: DateRange;
  setDates: (value: DateRange) => void;
}

const DateRangePicker: React.FC<DateRangePickerProps> = ({
  dates,
  setDates,
}) => {
  const handleFromChange = (newFrom: Dayjs | null) => {
    let newTo = dates.to;
    // "to" should never be before "from"
    if (newFrom?.isAfter(dates.to)) newTo = newFrom;
    if (newFrom != null) setDates({ from: newFrom, to: newTo });
  };

  const handleToChange = (newTo: Dayjs | null) => {
    let newFrom = dates.from;
    // "from" should never be after "to"
    if (newTo?.isBefore(dates.from)) newFrom = newTo;
    if (newTo != null) setDates({ from: newFrom, to: newTo ?? dates.to });
  };

  return (
    <Grid container spacing={1} alignItems="center">
      <Grid item>
        <MobileDatePicker
          inputFormat="DD.MM.YYYY"
          value={dates.from}
          onChange={handleFromChange}
          closeOnSelect
          // limit the dates which can be picked to the range we have results in
          minDate={dayjs('01.01.1996')}
          maxDate={dayjs()}
          renderInput={(params) => (
            <TextField {...params} size="small" sx={{ width: '13ch' }} />
          )}
        />
      </Grid>
      <Grid item>
        <Tooltip title="Find fact checks published between these dates">
          <Typography>to</Typography>
        </Tooltip>
      </Grid>
      <Grid item>
        <MobileDatePicker
          inputFormat="DD.MM.YYYY"
          value={dates.to}
          onChange={handleToChange}
          closeOnSelect
          // limit the dates which can be picked to the range we have results in
          minDate={dayjs('01.01.1996')}
          maxDate={dayjs()}
          renderInput={(params) => (
            <TextField {...params} size="small" sx={{ width: '13ch' }} />
          )}
        />
      </Grid>
    </Grid>
  );
};

export default DateRangePicker;
