import { Grid, Typography, Paper, Link } from '@mui/material';
import React from 'react';
import DisplayLink from '../DisplayLink/DisplayLink';
import SupportIndicator from '../SupportIndicator/SupportIndicator';
interface SourceProps {
  claim: Claim;
  /* The source supporting/rejecting its corresponding claim */
  source: Source;
  onFeedbackSourceRelevance: OnFeedbackSourceRelevance;
  onFeedbackSourceSupport: OnFeedbackSourceSupport;
}

/*
 * Displays a single source
 */
export const SourceItem: React.FC<SourceProps> = ({ source }) => {
  // TODO: #98 Implement custom support indicator

  const clampText = (text: string, maxChars: number) => {
    if (text.length >= maxChars) return text.slice(0, maxChars) + '...';
    return text;
  };

  return (
    <Paper elevation={0}>
      <Grid container direction="column" className="SourceItem" spacing={1}>
        <Grid item>
          <DisplayLink
            displayLink={source.domain ?? ''}
            link={source.url ?? ''}
          />
        </Grid>
        {source.softmaxScore?.[1] && (
          <Grid item container spacing={1} alignItems={'stretch'}>
            <Grid item xs>
              <SupportIndicator
                score={source.softmaxScore[1]}
                labels={['Disputing', 'Balanced', 'Supporting']}
                tooltips={[
                  'This source is disputing the claim.',
                  'This source is ambivalent to the claim.',
                  'This source is supporting the claim.',
                ]}
              />
            </Grid>
            {/* <Grid item xs={1}>
              <FeedbackSourceSupport
                claim={claim}
                source={source}
                onFeedbackSourceSupport={onFeedbackSourceSupport}
              />
            </Grid> */}
          </Grid>
        )}
        {source?.title && (
          <Grid item container spacing={1} alignItems={'center'}>
            <Grid item xs>
              <Link
                href={source.url}
                color="textSecondary"
                rel="noopener noreferrer"
                target="_blank"
              >
                <Typography variant="h5">
                  {clampText(source.title, 80)}
                </Typography>
              </Link>
            </Grid>
            {/* <Grid item xs={1}>
              <FeedbackSourceRelevance
                claim={claim}
                source={source}
                onFeedbackSourceRelevance={onFeedbackSourceRelevance}
              />
            </Grid> */}
          </Grid>
        )}
        <Grid item>
          <Typography variant="body1">
            {clampText(source.snippet ?? '', 160)}
          </Typography>
        </Grid>
        {source.publishDate != '' && source.publishDate != undefined && (
          <Grid item>
            <Typography variant="subtitle1">
              {'Published on ' +
                new Date(source.publishDate).toLocaleDateString()}
            </Typography>
          </Grid>
        )}
        <Grid item>
          <Typography variant="subtitle1">
            Source found on: {source.searchEngine}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default SourceItem;
